enum StatusCode {
  NOT_LOGIN = 401
}

enum RESTMethodPath {
  LOGIN = '/v1/user/login',
  ME = '/v1/user/me',
  BOOKMARK_EXISTS = '/v1/bookmark/exists',
  ADD_BOOKMARK = '/v1/bookmark/add',
  ADD_URL_BOOKMARK = '/v1/bookmark/add_url',
  DELETE_BOOKMARK = '/v1/bookmark/del',
  BOOKMARK_DETAIL = '/v1/bookmark/detail',
  BOOKMARK_LIST = '/v1/bookmark/list',
  BOOKMARK_AI_SUMMARIES = '/v1/aigc/summaries',
  BOOKMARK_ARCHIVE = '/v1/bookmark/archive',
  BOOKMARK_STAR = '/v1/bookmark/star',
  REPORT_FEEDBACK = '/v1/user/report',
  GET_BIND_LINK = '/v1/user/bind_link',
  GET_BIND_LIST = '/v1/user/bind_list',
  USER_INFO = '/v1/user/userinfo',
  USER_INFO_SETTING = '/v1/user/setting',
  USER_INFO_ENABLE_SETTING = '/v1/user/setting/enable',
  USER_INFO_DISABLE_SETTING = '/v1/user/setting/disable',
  CREATE_SUBSCRIPTION = '/v1/subscription/create_subscription',
  CANCEL_SUBSCRIPTION = '/v1/subscription/cancel'
}

enum LocalStorageKey {
  USER_TOKEN = 'local:token',
  USER_BOOKMARKS = 'local:bookmarks'
}

export { RESTMethodPath, StatusCode, LocalStorageKey }
