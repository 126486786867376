import { isFunction, isPromise } from './is'

export enum RequestMethodType {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete'
}

// fetch请求配置
interface FetchConfig {
  baseUrl: string
  requestInterceptors?: (options: FetchOptions) => FetchOptions | Promise<FetchOptions>
  responseInterceptors?: <T = unknown>(response: FetchResult<unknown>) => FetchResult<T> | Promise<FetchResult<T>>
  errorInterceptors?: (error: unknown) => unknown
}

type StreamCallbackFunc = (handler: (text: string, done: boolean) => void) => Promise<void>

// fetch请求参数
interface FetchOptions {
  url: string
  method?: RequestMethodType
  query?: Record<string, number | string>
  body?: unknown
  headers?: Record<string, string>
  stream?: boolean
}

// fetch结果
export interface FetchResult<T> {
  data: T
  status: number
  message: string
}

export class FetchRequest {
  options: FetchConfig

  constructor(options: FetchConfig) {
    this.options = options
  }

  async get<T = unknown>(options: FetchOptions) {
    return await this.handleRequest<T, FetchOptions>({
      ...options,
      method: RequestMethodType.get
    })
  }

  async post<T = unknown>(options: FetchOptions) {
    return await this.handleRequest<T, FetchOptions>({
      ...options,
      method: RequestMethodType.post
    })
  }

  async put<T = unknown>(options: FetchOptions) {
    return await this.handleRequest<T, FetchOptions>({
      ...options,
      method: RequestMethodType.put
    })
  }

  async delete<T = unknown>(options: FetchOptions) {
    return await this.handleRequest<T, FetchOptions>({
      ...options,
      method: RequestMethodType.delete
    })
  }

  async stream(options: FetchOptions) {
    return await this.handleRequest<(handler: (text: string, done: boolean) => void) => Promise<void>, FetchOptions>({
      ...options,
      stream: true
    })
  }

  private async handleRequest<T, F extends FetchOptions>(options: F): Promise<(F['stream'] extends true ? StreamCallbackFunc : T) | undefined> {
    type ResultType = F['stream'] extends true ? StreamCallbackFunc : T
    try {
      const requestInterceptors = this.options.requestInterceptors
      if (requestInterceptors && isFunction(requestInterceptors)) {
        if (isPromise(requestInterceptors)) {
          options = (await requestInterceptors(options)) as F
        } else {
          options = requestInterceptors(options) as F
        }
      }

      const { url, query, body, headers, method, stream } = options
      const result = await fetch(this.combineUrlWithQuery(url, query), {
        method,
        headers: {
          'Content-Type': !stream ? 'application/json' : 'text/event-stream',
          ...headers
        },
        body: body ? JSON.stringify(body) : undefined
      })

      if (stream) {
        const data = result.body
        if (!data) {
          throw new Error(`返回空数据`)
        }

        const callBack: StreamCallbackFunc = async (handler: (text: string, done: boolean) => void) => {
          const reader = data.getReader()
          const decoder = new TextDecoder('utf-8')
          let done = false
          while (!done) {
            const { value, done: readerDone } = await reader.read()
            done = readerDone
            if (value) {
              const char = decoder.decode(value, { stream: true })
              if (char) {
                handler(char, done)
              }
            }
          }

          handler('', done)
        }

        return callBack as ResultType
      }

      const knownErrorRefs: Record<number, string> = {
        401: '请求失败，请重新登录'
      }

      const responseInterceptors = this.options.responseInterceptors

      let response: FetchResult<T> | FetchResult<string> | null = null
      if (result.status !== 200) {
        if (knownErrorRefs[result.status]) {
          response = {
            data: '',
            status: result.status,
            message: knownErrorRefs[result.status]
          }
        } else {
          const message = (await result.json()) as { message: string }
          throw new Error(`fetch fail: ${message ? message.message : 'unknow error'}`)
        }
      } else {
        response = (await result.json()) as FetchResult<T>
      }

      if (responseInterceptors && isFunction(responseInterceptors)) {
        if (isPromise(responseInterceptors)) {
          return (await responseInterceptors(response)).data as ResultType
        } else {
          return (responseInterceptors(response) as FetchResult<T>).data as ResultType
        }
      }

      return response.data as ResultType
    } catch (error) {
      if (this.options.errorInterceptors) {
        this.options.errorInterceptors(error)
      }
      throw error
    }
  }

  private combineUrlWithQuery(url: string, query?: Record<string, number | string>) {
    const apiUrl = `${this.options.baseUrl}${url}`

    if (!query) {
      return apiUrl
    }

    const queryStr = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&')
    return `${apiUrl}?${queryStr}`
  }
}
