import { RESTMethodPath } from '@commons/types/const'
import { useCookies } from '@vueuse/integrations/useCookies'

const { set, remove } = useCookies()

const useAuth = {
  async requestAuth(redirectTarget: string, affCode: string) {
    const $config = useNuxtApp().$config.public
    const url = `https://accounts.google.com/o/oauth2/v2/auth`
    const params = {
      client_id: $config.googleOauthClientId as string,
      redirect_uri: `${$config.publicBaseUrl}/auth`,
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      response_type: 'code',
      state: JSON.stringify({
        nonce: 'random',
        redirectUrl: `${$config.publicBaseUrl}/auth`,
        target: redirectTarget,
        affCode: affCode
      })
    }

    location.href = url + '?' + new URLSearchParams(params)
  },
  async grantAuth(code: string, redirectUri: string, affCode: string): Promise<string> {
    request
    const resp = await request.post<{ token: string }>({
      url: RESTMethodPath.LOGIN,
      body: {
        code,
        aff_code: affCode,
        redirect_uri: redirectUri
      }
    })
    if (!resp) {
      throw new Error('login failed')
    }

    set('token', resp.token, { path: '/', expires: new Date(Date.now() + 60 * 24 * 60 * 60 * 1000) })
    return resp.token
  },
  async clearAuth(): Promise<void> {
    remove('token', { path: '/' })
  }
}

export default () => useAuth
