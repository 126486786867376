import { FetchRequest, type FetchResult } from '@commons/utils'
import { useCookies } from '@vueuse/integrations/useCookies'
import Toast from '~/components/Toast'

const { get } = useCookies()
export const request = new FetchRequest({
  baseUrl: useNuxtApp().$config.public.apiBaseUrl as string,
  requestInterceptors: async options => {
    const token = get('token')
    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
        ...(options.headers ?? {})
      }
    }
    return options
  },
  responseInterceptors: async <T = unknown>(response: FetchResult<unknown>) => {
    if (response.status === 401) {
      await useAuth().clearAuth()
      await navigateTo('/login')
    }

    return response as FetchResult<T>
  },
  errorInterceptors: error => {
    console.log(error)
    Toast.showToast({
      text: `${error}`
    })
  }
})
