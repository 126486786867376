<template>
  <Transition name="toast" @after-leave="onAfterLeave">
    <div class="text-toast" :class="{ success }" v-show="showToast">
      <span>{{ text }}</span>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { nextTick, onUnmounted, ref } from 'vue'

const emits = defineEmits(['dismiss'])
defineProps({
  text: String,
  success: Boolean
})

const showToast = ref<boolean>(false)
nextTick(() => {
  showToast.value = true

  setTimeout(() => {
    showToast.value = false
  }, 2500)
})

onUnmounted(() => {
  console.log('component dismiss.')
})
const onAfterLeave = () => {
  emits('dismiss')
}
</script>

<style lang="scss" scoped>
.text-toast {
  --style: select-none max-w-md min-w-140px flex-center rounded-lg bg-black bg-opacity-50 flex-wrap whitespace-break-spaces text-center color-white text-md overflow-hidden
    text-ellipsis py-4 px-5;

  &.success {
    --style: pt-4 px-6 border-1 border-solid border-emerald-2 bg-emerald-1 text-emerald-5 shadow-green-1 shadow-sm;
  }

  span {
    --style: w-full max-h-10 overflow-hidden text-ellipsis whitespace-nowrap;
  }
}

.toast-leave-to {
  --style: -translate-y-full;
}

.toast-enter-from {
  --style: translate-y-full;
}

.toast-leave-to,
.toast-enter-from {
  --style: opacity-0;
}

.toast-enter-active,
.toast-leave-active {
  --style: transition-all duration-250 ease-in-out;
}
</style>

<!-- eslint-disable vue-scoped-css/enforce-style-type -->
<style lang="scss">
.toast.toast-start {
  --style: fixed bottom-0 left-0 w-full h-full flex items-center flex-col-reverse pb-4xl;

  & > * {
    --style: 'not-first:mb-4xl';
  }
}
</style>
